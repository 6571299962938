<template>
  <div class="app">
    <NavbarComp/>
    <MainView/>
    <AboutView/>
    <TokenomicsView/>
    <BuyNowView/>
    <GalleryView/>
    <FooterComp/>
    <div class="bg2"/>
  </div>
</template>

<script setup lang="ts">
import MainView from "@/views/MainView.vue";
import NavbarComp from "@/components/NavbarComp.vue";
import AboutView from "@/views/AboutView.vue";
import TokenomicsView from "@/views/TokenomicsView.vue";
import GalleryView from "@/views/GalleryView.vue";
import FooterComp from "@/components/FooterComp.vue";
import BuyNowView from "@/views/BuyNowView.vue";
import {onMounted} from "vue";
import VideoView from "@/views/VideoView.vue";

onMounted(() => {
  let threshold;
  window.innerWidth > 450 ? threshold = 0.35 : threshold = 0.15;

  let observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        (entry.target as HTMLElement).style.opacity = '1';
        observer.unobserve(entry.target);
      }
    });
  }, {
    threshold: threshold
  });

  const targetElements = document.querySelectorAll('.about_content, .buy-now_content, .tokenomics_content, .memes_content');

  targetElements.forEach(targetElement => {
    observer.observe(targetElement);
  });

})
</script>

<style lang="sass">
.glass
  position: absolute
  top: 457px
  left: 57px
*
  margin: 0
  padding: 0
  box-sizing: border-box
  cursor: url("@/assets/aim.png") 20 20, auto
.app
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  min-height: 100vh
  min-width: 100vw
  font-family: "Barlow Condensed", sans-serif
  font-weight: 500
  font-style: normal
  background-image: linear-gradient(to bottom, rgba(3, 81, 225, 0.5), rgba(166, 225, 255, 0.5), rgba(0, 53, 152, 0.5))

  h1, h2 ,h3
    font-family: 'FortniteFont', sans-serif
  button
    font-family: "Barlow Condensed", sans-serif
#jupiter-terminal
  span, div, button
    font-family: "Barlow Condensed", sans-serif
  .flex.items-center.py-2.px-3.rounded-2xl.h-7
    background-color: #efc727
    border-radius: 8px
    border: 4px solid #8c741b
  .py-2.px-3.h-7.flex.items-center.rounded-2xl.text-xs
    background-color: #efc727
    border-radius: 8px
    border: 4px solid #8c741b
  .p-5.text-md.font-semibold.h-full.w-full.leading-none
    background-color: #efc727
    border-radius: 8px
    border: 4px solid #8c741b
dialog
  min-width: 100vw
  min-height: 100vh
  font-family: "Barlow Condensed", sans-serif
.bg1
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: -1
.bg2
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-image: url("@/assets/bg.jpg")
  background-size: cover
  background-attachment: fixed
  z-index: -2
</style>
