<template>
  <div class="memes" id="memes">
    <RunningText text1="MEMES" text2="GALLERY"/>
    <div class="memes_content">
      <div class="gallery">
        <div v-for="(line, lineIndex) in randomLines" :key="lineIndex" class="gallery-line">
          <div v-for="(image, imageIndex) in line" :key="imageIndex" :class="['gallery-item', image.type]">
            <img :src="image.src" :alt="'Image ' + (lineIndex * 4 + imageIndex + 1)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import RunningText from "@/components/RunningText.vue";
import { ref, computed } from 'vue';

const images = ref([
  { src: require('@/assets/memes/img1.jpg') },
  { src: require('@/assets/memes/img2.jpg') },
  { src: require('@/assets/memes/img3.jpg') },
  { src: require('@/assets/memes/img4.jpg') },
  { src: require('@/assets/memes/img5.jpg') },
  { src: require('@/assets/memes/img6.jpg') },
  { src: require('@/assets/memes/img7.jpg') },
  { src: require('@/assets/memes/img8.jpg') },
  { src: require('@/assets/memes/img9.jpg') },
  { src: require('@/assets/memes/img10.jpg') },
  { src: require('@/assets/memes/img11.jpg') },
  { src: require('@/assets/memes/img12.jpg') },
  { src: require('@/assets/memes/img13.jpeg') },
  { src: require('@/assets/memes/img14.jpeg') },
  { src: require('@/assets/memes/img15.jpeg') },
  { src: require('@/assets/memes/img16.jpeg') },
]);

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const randomLines = computed(() => {
  const shuffledImages = shuffleArray([...images.value]);
  const lines = [];

  for (let i = 0; i < shuffledImages.length; i += 4) {
    const line = shuffledImages.slice(i, i + 4);
    const randomOrder = shuffleArray(['wide', 'narrow', 'wide', 'narrow']);

    const randomLine = line.map((image, index) => ({
      ...image,
      type: randomOrder[index],
    }));

    lines.push(randomLine);
  }

  return lines;
});
</script>

<style scoped lang="sass">
.tokenomics
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  max-height: 100vh
  width: 100%
  padding: 20px 40px 60px
  &_content
    display: flex
    align-items: flex-start
    justify-content: center
    width: 50%
    max-width: 1450px
    gap: 30px
    &_info
      margin-top: 200px
      display: flex
      flex-direction: column
      align-items: flex-start
      justify-content: space-between
      background-color: rgba(0, 67, 215, 0.75)
      outline: 3px solid rgba(3, 19, 80, 0.65)
      box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.4)
      padding: 60px 40px 40px
      width: 100%
      height: 300px
      position: relative
      & img
        position: absolute
        top: -230px
        left: 50%
        transform: translateX(-50%)
        width: 300px
      &_item
        display: flex
        align-items: center
        gap: 20px
        &_title
          font-size: 25pt
          font-family: 'FortniteFont', sans-serif
          background-color: #efc727
        &_text
          font-size: 20pt
          color: white
</style>

<style lang="sass" scoped>
.memes
  width: calc(100vw)
  border-top: none
  display: flex
  align-items: center
  flex-direction: column
  padding: 20px 40px 60px
  &_content
    opacity: 0
    transition: opacity 0.7s
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    max-width: 1140px
    width: 100%
    gap: 40px
    & h2
      color: white
      font-size: 35pt
      text-shadow: 2px 2px 10px #86bce7

</style>

<style lang="scss" scoped>
.gallery {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.gallery-line {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}

.gallery-item {
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  border: 3px solid #0068ef;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.4);

  &:hover {
    transform: scale(1.05);
    z-index: 10;
  }

  &.wide {
    grid-column: span 2;
    aspect-ratio: 2 / 1;
  }

  &.narrow {
    grid-column: span 1;
    aspect-ratio: 1 / 1.05;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}
</style>
<style lang="sass" scoped>
@media (max-width: 760px)
  .gallery-line
    grid-template-columns: repeat(4, 1fr)
</style>