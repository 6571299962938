<template>
  <div class="fading-container">
    <div class="content">
      <div class="marquee">
        <span class="text" v-for="text in 10" :key="text">
          <span style="color: #efc727; margin-right: 20px">{{ props.text1 }}</span> {{ props.text2 }}
          <img src="@/assets/head.png" alt="head">
        </span>
      </div>
      <div class="marquee">
        <span class="text" v-for="text in 10" :key="text + 10">
          <span style="color: #efc727; margin-right: 20px">{{ props.text1 }}</span> {{ props.text2 }}
          <img src="@/assets/head.png" alt="head">
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  text1: string;
  text2: string
}>()
</script>

<style lang="sass" scoped>
.fading-container
  width: 90%
  max-width: 1450px
  overflow: hidden
  position: relative
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 4%, rgb(0, 0, 0) 13%, rgb(0, 0, 0) 87%, rgba(0, 0, 0, 0) 96%)
  margin: 0 auto 60px

.content
  display: flex
  white-space: nowrap
  overflow: hidden
  position: relative

.marquee
  display: flex
  animation: scroll 80s linear infinite
  & span.text
    display: flex
    align-items: center
    color: white
    font-size: 55pt
    font-weight: 600
    font-family: 'FortniteFont', sans-serif
img
  margin: 0 20px 15px
@keyframes scroll
  0%
    transform: translateX(0)
  100%
    transform: translateX(-100%)
@media (max-width: 700px)
  .fading-container
    width: 100%
    margin: 0 auto 20px
  .marquee
    & span.text
      font-size: 35pt
@media (max-width: 430px)
  .marquee
    & span.text
      font-size: 28pt
</style>
