<template>
  <div class="buy-now" id="swap">
    <RunningText text1="BUY" text2="$JONESY NOW"/>
    <div class="buy-now_content">
      <div id="integrated-terminal"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import {onMounted} from "vue";
import RunningText from "@/components/RunningText.vue";

onMounted(() => {

  window.Jupiter.init({
    displayMode: "integrated",
    integratedTargetId: "integrated-terminal",
    endpoint: "https://twilight-maximum-snow.solana-mainnet.quiknode.pro/dd04ed9fb2740bea8c314827d948e4c4c1e22604/",
    strictTokenList: false,
    formProps: {
      fixedOutputMint: true,
      initialOutputMint: "3uPg7L1n575iWD6kKGGGg7etcGurS9UsR1tcs7b3pump",
    },
  });

})
</script>

<style lang="sass" scoped>
.buy-now
  width: calc(100vw)
  border-top: none
  display: flex
  align-items: center
  flex-direction: column
  padding-bottom: 40px
  &_content
    opacity: 0
    transition: opacity 0.7s
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    max-width: 1140px
    width: 100%
    gap: 40px
    & h2
      color: white
      font-size: 35pt
      text-shadow: 2px 2px 10px #86bce7
#integrated-terminal
  width: 600px
  height: auto
  padding: 10px 20px
  background-color: rgba(75, 75, 75, 0.63)
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.4)
  outline: 3px solid #505050
  border-radius: 2px
@media (max-width: 760px)
  #integrated-terminal
    width: 80%
</style>