<template>
  <div id="threeContainer" ref="threeContainer"></div>

</template>

<script setup>
import { onMounted, ref } from 'vue';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const threeContainer = ref(null);

onMounted(() => {
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(75, 500 / window.innerHeight, 0.1, 1000);

  const renderer = new THREE.WebGLRenderer({ alpha: true });
  renderer.setPixelRatio(window.devicePixelRatio);

  // Функция для установки размеров рендерера и камеры
  const setSize = () => {
    const container = threeContainer.value;
    const width = container.offsetWidth;
    const height = container.offsetHeight;
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    renderer.setSize(width, height);
  };

  // Установка начальных размеров
  setSize();
  threeContainer.value.appendChild(renderer.domElement);

  // Настройка освещения
  const ambientLight = new THREE.AmbientLight(0xffffff); // Равномерное освещение
  scene.add(ambientLight);

  const fixedLight = new THREE.DirectionalLight(0xffffff, 2); // Направленный свет
  fixedLight.position.set(5, 10, 7).normalize();
  scene.add(fixedLight);

  let model = null; // Объявляем переменную для хранения модели

  // Загрузка модели GLTF
  const loader = new GLTFLoader();
  loader.load(
      '/models/jonesy/scene.gltf',
      (gltf) => {
        console.log('Model loaded');
        model = gltf.scene;
        model.scale.set(3.5, 3.5, 3.5);

        // Вычисление центра модели и корректировка позиции
        const box = new THREE.Box3().setFromObject(model);
        const center = new THREE.Vector3();
        box.getCenter(center);
        model.position.sub(center); // Перемещаем модель так, чтобы её центр оказался в начале координат

        // Добавляем модель в сцену
        scene.add(model);

        // Если модель имеет анимации, запустим их
        if (gltf.animations && gltf.animations.length) {
          const mixer = new THREE.AnimationMixer(model);
          gltf.animations.forEach((clip) => mixer.clipAction(clip).play());

          // Обновление анимаций в рендер цикле
          const clock = new THREE.Clock();
          function animate() {
            requestAnimationFrame(animate);
            mixer.update(clock.getDelta());
            controls.update(); // Обновляем управление
            renderer.render(scene, camera);
          }
          animate();
        }
      },
      undefined,
      (error) => {
        console.error('Error loading model:', error);
      }
  );

  // Настройка камеры
  camera.position.set(0, 1, 5);
  camera.lookAt(0, 1, 0);

  // Настройка управления камерой
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true; // Включает плавное замедление при вращении
  controls.dampingFactor = 0.25; // Фактор замедления
  controls.enableZoom = true; // Включает зум
  controls.enablePan = false; // Отключает панорамирование

  // Основной цикл анимации
  const animate = () => {
    requestAnimationFrame(animate);
    if (model) {
      model.rotation.y += 0.005; // Плавное вращение модели вокруг оси Y
    }
    controls.update(); // Обновляем управление
    renderer.render(scene, camera);
  };

  animate();

  // Обработка изменения размера окна
  window.addEventListener('resize', setSize);
});
</script>

<style scoped lang="sass">
#threeContainer
  border-radius: 2px
  outline: 3px solid rgba(75, 30, 117, 0.65)
  width: 300px
  height: 600px
  background-image: url("@/assets/fnbg1.jpg")
  background-size: contain
  background-attachment: fixed
  background-position: center
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.4)
</style>
